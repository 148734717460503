import React, { Fragment } from "react";
import Link from "next/link";
import Image from "next/image";
import video from "../../../public/img/video1.png";
import BYDGGOLD from "../../assets/images/BY-DG-GOLD.jpg";

import styles from "@/styles/home.module.scss";

const OtherProducts = () => {
  return (
    <Fragment>
      <section>
        <div className={styles.easy_button}>
          <div className="container">
            <div className="row g-3">
              <div className={`${styles.col_clg_3} ${"col-md-6"}`}>
                <Link href="https://mysenco.in/" passHref>
                  <div className={styles.shop_btn}>
                    <div className={styles.icon_w}>
                      <div className={styles.icon2}></div>
                    </div>
                    <aside>Senco Savings Schemes</aside>
                  </div>
                </Link>
              </div>
              <div className={`${styles.col_clg_3} ${"col-md-6"}`}>
                <Link href="/book-gold-online" passHref>
                  <div className={styles.shop_btn}>
                    <div className={styles.icon_w}>
                      <div className={styles.icon1}></div>
                    </div>
                    <aside>Advance Gold Booking</aside>
                  </div>
                </Link>
              </div>

              <div className={`${styles.col_clg_3} ${"col-md-6"}`}>
                <Link href="https://mydigigold.com/" target="_blank" passHref>
                  <div className={styles.shop_btn}>
                    <div className={styles.icon_w}>
                      <div className={styles.icon3}></div>
                    </div>
                    <aside>
                      My <strong> Digi Gold</strong>
                    </aside>
                  </div>
                </Link>
              </div>
              <div className={`${styles.col_clg_3} ${"col-md-6"}`}>
                <Link href="/jewellery?q=&try_it_online=yes">
                  <a className={styles.shop_btn}>
                    <div className={styles.icon_w}>
                      <div className={styles.icon4}></div>
                    </div>
                    <aside>Virtual Try On</aside>
                  </a>
                </Link>
              </div>
              <div className={`${styles.col_clg_3} ${"col-md-6"}`}>
                <Link href="/bookanappointment">
                  <a className={styles.shop_btn} target="_blank">
                    <div className={styles.icon_w}>
                      <div className={styles.icon8}></div>
                    </div>
                    <aside>Store Appoinment Booking</aside>
                  </a>
                </Link>
              </div>
              <div className={`${styles.col_clg_3} ${"col-md-6"}`}>
                <Link href="/book-silver-online">
                  <a className={styles.shop_btn}>
                    <div className={styles.icon_w}>
                      <div className={styles.icon6}></div>
                    </div>
                    <aside>Advance Silver Booking</aside>
                  </a>
                </Link>
              </div>
              <div className={`${styles.col_clg_3} ${"col-md-6"}`}>
                <Link href="https://mydigisilver.com/" target="_blank">
                  <a className={styles.shop_btn}>
                    <div className={styles.icon_w}>
                      <div className={styles.icon5}></div>
                    </div>
                    <aside>
                      My <strong> Digi Silver</strong>
                    </aside>
                  </a>
                </Link>
              </div>

              <div className={`${styles.col_clg_3} ${"col-md-6"}`}>
                <Link href="/astro">
                  <a className={styles.shop_btn}>
                    <div className={styles.icon_w}>
                      <div className={styles.icon7}></div>
                    </div>
                    <aside>Senco Astrology</aside>
                  </a>
                </Link>
              </div>
              <div className={`${styles.col_clg_3} ${"col-md-6"}`}>
                <Link href="/gift-card">
                  <a className={styles.shop_btn}>
                    <div className={styles.icon_w}>
                      <div className={styles.icon10}></div>
                    </div>
                    <aside>Gift Cards</aside>
                  </a>
                </Link>
              </div>
              <div className={`${styles.col_clg_3} ${"col-md-6"}`}>
                <Link href="/deal-of-the-day">
                  <a className={styles.shop_btn}>
                    <div className={styles.icon_w}>
                      <div className={styles.icon9}></div>
                    </div>
                    <aside>Deal of the Day</aside>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default OtherProducts;
